import {debounce} from 'lodash'

export default {
    props: {
        value: {},
        label: {
            type: String,
            default: ''
        },
        progress: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        itemValue: {
            type: String,
            default: 'default'
        },
        itemTitle: {
            type: String,
            default: 'name'
        },
        itemDesc: {
            type: String,
            default: ''
        },

        disabled: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array,
            default: () => []
        },
        requestInterval: {
            type: Number,
            default: 100
        },
        isValid: {
            type: Boolean,
            default: true
        },
        load: {
            type: Boolean,
            default: false
        }

    },
    data() {
        return {
            openSelect: false,
            selectedItem: this.value,
        }
    },
    watch: {
        selectedItem() {
            this.$emit('input', this.selectedItem);
        },
        value() {
            this.selectedItem = this.value;
        }
    },
    created() {
        this.selectedItem = this.value;
    },
    methods: {
        changeSelect(item) {
            this.selectedItem = item[this.itemTitle];
            this.$emit('input', this.selectedItem);
        },

        handleInput: debounce(function (event) {
            this.$emit('handle-input', event.target.value);
        }, 10),
        focusInput() {
            this.$emit('focus-input');
        },
        focusOutInput() {
            this.$emit('focus-out-input');
        },
        changeShowTooltipList(status) {
            setTimeout(() => {
                this.openSelect = status
            }, 22)
        }
    }
}
